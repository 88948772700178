import firebase from 'firebase/app'
import 'firebase/firestore'

export const config = {
    apiKey: 'AIzaSyANI-JmitI0Te7s_TPaGdSIALs1ztfzp7I',
    authDomain: 'covid-19-97965.firebaseapp.com',
    databaseURL: 'https://covid-19-97965.firebaseio.com',
    projectId: 'covid-19-97965',
    storageBucket: 'covid-19-97965.appspot.com',
    messagingSenderId: '257184481989',
    appId: '1:257184481989:web:9571a41001074e210366ac',
    measurementId: 'G-E3GTCS5V6P'
}

const app = firebase.initializeApp(config)

// export const firestore = firebase.firestore()

export default app
